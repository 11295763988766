import { FC, useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';

import { Recaptcha, RecaptchaRef } from '@components/Recaptcha/Recaptcha';
import { RecaptchaForm } from '@components/RecaptchaForm/RecaptchaForm';
import RichText from '@components/RichText/RichText';
import { authenticateUser } from '@customer-insights';
import { useProductsPublicSubmitBusinessLead } from '@dc/hooks';
import { Placeholder } from '@sitecore/common';
import { FormValues, RegisterToNewsletterRendering } from '@sitecore/types/manual/RegisterToNewsletterForm';
import { Box, Button, InputText, Stack, Stretch, Text, TextLink, TransitionOpacity } from '@sparky';
import { useTracking } from '@tracking';

const newsletterFormSchema = yup.object({
  emailAddress: yup.string().email().required(),
  recaptchaToken: yup.string().required(),
});

export const RegisterToNewsletterForm: FC<RegisterToNewsletterRendering> = ({ fields, params }) => {
  const { displayColumn, setFormState } = params ?? {};
  const { trackFormInteractionStart, trackFormInteractionCompleted } = useTracking();

  const {
    emailAddressFormField: { value: emailAddressValue = {}, editable: emailAddressEditable = undefined } = {},
    disclaimerContent,
    backButtonText,
    nextButtonText,
  } = fields ?? {};

  const recaptchaRef = useRef<RecaptchaRef>(null);
  const [isTransitioning, setTransitioning] = useState(false);
  const { send, reset, isError, isSuccess } = useProductsPublicSubmitBusinessLead();
  const methods = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(newsletterFormSchema),
  });
  const {
    formState: { errors },
    register,
  } = methods;

  const submitForm: SubmitHandler<FormValues> = async values => {
    setFormState?.('loading');

    setTransitioning(true);
    await send(values);
    authenticateUser(values.emailAddress);

    setTimeout(() => {
      setTransitioning(false);
    }, 500);
  };

  const getEmailErrorMessage = () => {
    const defaultErrorMessage = errors.emailAddress?.message;
    const { requiredMessage, validationMessage } = emailAddressValue ?? {};

    if (errors.emailAddress?.type === 'required') {
      return requiredMessage ?? defaultErrorMessage;
    } else if (errors.emailAddress?.type === 'email' && validationMessage) {
      return validationMessage ?? defaultErrorMessage;
    }

    return undefined;
  };

  useEffect(() => {
    if (isSuccess) {
      trackFormInteractionCompleted();
      setFormState?.('success');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (setFormState && isError) {
      setFormState('error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <TransitionOpacity in={!isTransitioning}>
      <Stack gap="6">
        {!isSuccess && !isError && (
          <>
            <Placeholder name="jss-newsletter-form-intro" />
            <FormProvider {...methods}>
              <RecaptchaForm onSubmit={submitForm} recaptchaRef={recaptchaRef} onFocus={trackFormInteractionStart}>
                <Stack gap="6">
                  <Stack
                    direction={displayColumn ? 'column' : { initial: 'column', md: 'row' }}
                    gap="6"
                    alignY="justify"
                    alignX={{ initial: undefined, md: 'justify' }}>
                    <Stack.Item grow>
                      <InputText
                        {...register('emailAddress')}
                        label={emailAddressValue?.label ?? `Email`}
                        placeholder={emailAddressValue?.placeholder ?? `mijn@email.nl`}
                        hint={emailAddressValue?.hint}
                        error={getEmailErrorMessage()}
                      />
                    </Stack.Item>
                    {/** Box padding hack to prevent jumping when there's a validation error */}
                    <Box paddingTop={displayColumn ? '0' : { md: '7' }}>
                      <Stretch width={{ initial: true, md: false }} height={false}>
                        <Button size="compact" type="submit">
                          {nextButtonText?.value}
                        </Button>
                      </Stretch>
                    </Box>
                  </Stack>
                  <Text size="BodyS">
                    <RichText html={disclaimerContent?.value} />
                  </Text>
                  <Text size="BodyS">
                    <Recaptcha hasError={!!errors.recaptchaToken} ref={recaptchaRef} />
                  </Text>
                </Stack>
              </RecaptchaForm>
            </FormProvider>
          </>
        )}

        {isSuccess || emailAddressEditable ? (
          <>
            {emailAddressEditable && 'This is the success message placeholder'}
            <Placeholder name="jss-newsletter-form-success" />
          </>
        ) : null}

        {isError || emailAddressEditable ? (
          <>
            {emailAddressEditable && 'This is the error message placeholder'}
            <Placeholder name="jss-newsletter-form-error" />
          </>
        ) : null}

        {(isSuccess || isError) && (
          <TextLink
            type="button"
            onClick={() => {
              setFormState?.('loading');
              setTransitioning(true);
              setTimeout(() => {
                reset();
                setTransitioning(false);
                setFormState?.('initial');
              }, 500);
            }}
            emphasis="high">
            {backButtonText?.value}
          </TextLink>
        )}
      </Stack>
    </TransitionOpacity>
  );
};

export default RegisterToNewsletterForm;
